import { createSignal } from "solid-js";
import type { AComponent } from "aframe";
import type { ActionButtonType, Training } from "./ExecutableActionTypes";
import type { Banner, ObjectInfo, SceneElements, TeleportableZone } from "./SceneElementsTypes";
import { createStore } from "solid-js/store";

export const [tenantId, setTenantId] = createSignal("");
export const [interfaceHidden, setInterfaceHidden] = createSignal(false);
export const [isReconnecting, setIsReconnecting] = createSignal(false);

export const [appName, setAppName] = createSignal("AlloCola");
export const [sceneTitle, setSceneTitle] = createSignal("");
export const [sceneModel, setSceneModel] = createSignal("");
export const [branding, setBranding] = createSignal("");
export const [sceneElements, setSceneElements] = createSignal<SceneElements>({
  sceneTitle: "",
  sceneModel: "",
});
export const [baseURLForAssets, setBaseURLForAssets] = createSignal("");
export const [banner, setBanner] = createSignal<Banner | null>(null);
export const [objectsInfos, setObjectsInfos] = createSignal<Array<ObjectInfo>>([]);
export const [actionButtons, setActionButtons] = createSignal<Array<ActionButtonType>>([]);
export const [training, setTraining] = createStore<Training>({ ui: "advanced", label: "", sections: [] });
export const [currentTrainingSection, setCurrentTrainingSection] = createSignal(-1);
export const [currentTrainingLesson, setCurrentTrainingLesson] = createSignal(-1);
export const currentOpenedPanel: { setState: ((newState: boolean) => void) | null } = { setState: null };
export const [teleportableZones, setTeleportableZones] = createSignal<Array<TeleportableZone>>([]);
export const [isInVR, setIsInVR] = createSignal(false);
export const [loadingPercent, setLoadingPercent] = createSignal(0);
export const [muteAllSoundsExceptTTS, setMuteAllSoundsExceptTTS] = createSignal(false);
export const [showRestartExperienceButton, setShowRestartExperienceButton] = createSignal(false);

export const [clientId, setClientId] = createSignal("");
const ls = typeof localStorage !== "undefined" ? localStorage : undefined; // to be able to run tests without localStorage
export const [pseudo, setPseudo] = createSignal(ls?.getItem("pseudo") || "");
export const [pseudoWithoutDollar, setPseudoWithoutDollar] = createSignal(ls?.getItem("pseudo") || "");
// instead of createMemo, we use a signal and effect in App component to avoid the warning
// computations created outside a `createRoot` or `render` will never be disposed
// export const pseudoWithoutDollar = createMemo(() => {
//   return pseudo().replace("$", "");
// });
export const [userCompany, setUserCompany] = createSignal("");
export const [userRole, setUserRole] = createSignal("");
export const [userEmail, setUserEmail] = createSignal("");
export const [userPhone, setUserPhone] = createSignal("");

export const [aiChatOpen, setAIChatOpen] = createSignal(false);
export const [aiAgent, setAIAgent] = createSignal<typeof AComponent | null>(null);
export const [aiAgentMuted, setAIAgentMuted] = createSignal(false);
export const [aiAgentPersonality, setAIAgentPersonality] = createSignal("");
export const [pendingMessage, setPendingMessage] = createSignal("");

export const isStaff = (speaker: string) =>
  speaker === "Vincent" ||
  speaker === "Romain" ||
  speaker === "Ranim" ||
  speaker === "Florian" ||
  speaker.startsWith("Alice") ||
  speaker.startsWith("Eric") ||
  speaker.startsWith("Éric");

export const currentUserIsStaff = () => {
  const speaker = pseudoWithoutDollar().split(" ")[0];
  return isStaff(speaker);
};

export const userIsAllowedToUseAI = (username: string) => {
  if (isStaff(username)) return true;
  const agent = aiAgent();
  if (!agent) return false;
  return agent.data.enabledForAll;
};

export function sendLinkToQuest(linkUrl: string) {
  const sendToQuestUrl = new URL("https://oculus.com/open_url/");
  sendToQuestUrl.searchParams.set("url", linkUrl);
  // @ts-ignore
  window.location.href = sendToQuestUrl;
}
