import { createResource } from "solid-js";

export interface IceServers {
  urls: Array<string>;
  username: string;
  credential: string;
}

export interface ServerInfo {
  iceServers?: IceServers;
  permsToken?: string;
  serverURL: string;
}

export const fetchServerConfig = async (): Promise<ServerInfo> => {
  const res = await fetch("/serverConfig.json");
  return res.json();
};

export const routeData = () => {
  const [serverConfig] = createResource<ServerInfo>(fetchServerConfig);
  return serverConfig;
};
